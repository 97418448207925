import { logorumah } from "../../assets/img/icon/index";
import { logo1 } from "../../assets/img/icon/index";
import Text from "../atoms/Text";
import Section, { SectionProps } from "./Section";
import Image from "../atoms/Image";
import Heading from "../atoms/Heading";
import { Link } from "react-router-dom";
import { FooterData } from "../../utils/Footer";

const Footer = () => {
  const { beranda, kontak, layanan, overview, socialMedia } = FooterData;

  return (
    <footer className="w-full bg-slate-100 flex flex-col justify-center items-center overflow-hidden">
      <section className="w-full max-w-7xl grid grid-cols-2 gap-y-5 md:grid-cols-6 font-andika py-12 px-5 sm:px-20">
        <div className="col-span-2 grid gap-3 sm:mr-10 ">
          <Image src={logorumah} alt="Aruna Logo" />
          <Heading headingType="h3" customClass="text-xl text-[#252525]">
            Alamat:
          </Heading>
          <Text customClass="mb-7 md:mr-5">{overview.address}</Text>
          <Heading headingType="h3" customClass="text-xl text-[#252525]">
            Collaboration with
          </Heading>
          <Link to="https://saltacademy.id/" className="py-2 mb-5 md:mb-0">
            <Image src={logo1} alt="Collaboration with Salt Academy" />
          </Link>
        </div>
        <div>
          <Heading customClass="text-xl font-normal" headingType="h3">
            Beranda
          </Heading>
          {beranda.map((item, index) => {
            return (
              <Section
                key={index}
                content={item.content}
                clickableURL={item.clickableURL}
                iconSrc={item.iconSrc}
                for={item.for}
              />
            );
          })}
        </div>
        <div>
          <Heading customClass="text-xl font-normal" headingType="h3">
            Layanan
          </Heading>
          {layanan.map((item, index) => {
            return (
              <Section
                key={index}
                content={item.content}
                clickableURL={item.clickableURL}
                iconSrc={item.iconSrc}
                for={item.for}
              />
            );
          })}
        </div>
        <div>
          <Heading customClass="text-xl font-normal" headingType="h3">
            Kontak Kami
          </Heading>
          {kontak.map((item, index) => {
            return (
              <Section
                key={index}
                content={item.content}
                clickableURL={item.clickableURL}
                iconSrc={item.iconSrc}
                for={item.for}
              />
            );
          })}
        </div>
        <div>
          <Heading customClass="text-xl font-normal" headingType="h3">
            Sosial Media
          </Heading>
          {socialMedia.map((item, index) => {
            return (
              <Section
                key={index}
                content={item.content}
                clickableURL={item.clickableURL}
                iconSrc={item.iconSrc}
                for={item.for}
              />
            );
          })}
        </div>
      </section>
      <section className="bg-white w-full flex justify-center items-center py-8">
        <Text customClass="text-xs md:text-sm">
          Copyright ©2023 Rumah Aruna. All rights reserved.
        </Text>
      </section>
    </footer>
  );
};

export default Footer;
