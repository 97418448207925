type Props = {
  customClass?: string | undefined;
  firstElement: React.ReactNode;
  secondElement: React.ReactNode;
  secondElementClasses?: string;
  orientation?: "vertical" | "horizontal";
};

const Container = ({
  customClass,
  firstElement,
  secondElement,
  orientation,
  secondElementClasses
}: Props) => {
  let orientationClasses = "";

  switch (orientation) {
    case "vertical":
      orientationClasses = "w-full grid"
      break;
    case "horizontal":
      orientationClasses = "w-full grid md:grid-cols-2"
      break;
  }

  const finalClasses = `${orientationClasses} ${customClass}`;

  return (
    <section className={`w-full grid ${finalClasses}`}>
      <div className="w-full">{firstElement}</div>
      <div className={`w-full ${secondElementClasses}`}>{secondElement}</div>
    </section>
  );
};

export default Container;
