export const FooterData = {
  overview: {
    address:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  beranda: [
    { content: "Tentang Kami", clickableURL: "/about", iconSrc: "", for: "" },
    { content: "Kontak Kami", clickableURL: "/contact", iconSrc: "", for: "" },
    { content: "FAQ", clickableURL: "https://wa.me", iconSrc: "", for: "" },
    { content: "Artikel", clickableURL: "https://wa.me", iconSrc: "", for: "" },
  ],
  layanan: [
    {
      content: "Rumah Aruna",
      clickableURL: "https://wa.me/081515150002",
      iconSrc: "",
      for: "",
    },
    {
      content: "Aruna SDC",
      clickableURL: "https://wa.me/081284848615",
      iconSrc: "",
      for: "",
    },
  ],
  kontak: [
    {
      content: "Telp Rumah Aruna",
      clickableURL: "https://wa.me/081515150002",
      iconSrc: "",
      for: "",
    },
    {
      content: "Telp Rumah Aruna",
      clickableURL: "https://wa.me/081284848615",
      iconSrc: "",
      for: "",
    },
  ],
  socialMedia: [
    {
      content: "Instagram Rumah Aruna",
      clickableURL: "https://www.instagram/aruna_sdc",
      iconSrc: "",
      for: "",
    },
    {
      content: "Instagram Aruna SDC",
      clickableURL: "https://www.instagram/cosmic_club_rumaharuna",
      iconSrc: "",
      for: "",
    },
  ],
};
