import React, { useState } from "react";
import { Link } from "react-router-dom";

const Button = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    console.log(`Selected option: ${option}`);
    setIsOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="relative inline-block">
      <button className="flex" onClick={handleButtonClick}>
        <p>Layanan</p>
      </button>
      {isOpen && (
        <div className="w-[150px] absolute  text-black text-[18px] mt-2 py-2 w-30 text-center bg-white hover:shadow-sm rounded shadow-lg">
          <ul className="text-left">
            <li
              className="cursor-pointer px-4 py-2 "
              onClick={() => handleOptionClick("Rumah-Aruna")}
            >
              <Link to={`rumah-aruna`}>Rumah Aruna</Link>
            </li>
            <li
              className="cursor-pointer px-4 py-2 "
              onClick={() => handleOptionClick("Aruna SDC")}
            >
              <Link to={`aruna-sdc`}>Aruna SDC</Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Button;
