import { useState, useEffect } from "react";
import { Article } from "../interfaces/Article.interface";
import { HTTPAruna } from "../service/handlerApi";

const useArticleWithPagination = (
  initialPage = 1,
  initialLimit = 4
): {
  data: Article[];
  loading: boolean;
  error: boolean;
  currentPage: number;
  paginationMeta: any;
  handlePrevClick: () => void;
  handleNextClick: () => void;
} => {
  const [data, setData] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const limit = initialLimit;

  const [paginationMeta, setPaginationMeta] = useState<{
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  } | null>(null);

  const fetchArticles = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const response = await HTTPAruna.get(
        `/api/articles?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=*&sort[0]=id:desc`
      );

      setData(response.data.data);
      setLoading(false);
      setPaginationMeta(response.data.meta.pagination);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles(currentPage, limit);
  }, [currentPage, limit]);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextClick = () => {
    if (paginationMeta && currentPage < paginationMeta.pageCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return {
    data,
    loading,
    error,
    currentPage,
    paginationMeta,
    handleNextClick,
    handlePrevClick,
  };
};

export default useArticleWithPagination;
