import GlobalLayout from "../components/layouts/GlobalLayout";
import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Service } from "../interfaces/Service.interface";
import { useParams } from "react-router-dom";
import Heading from "../components/atoms/Heading";
import Container from "../components/atoms/Container";
import Image from "../components/atoms/Image";
import { Plane, calendar } from "../assets/img/icon";
import { formatDate } from "../utils/dateFormatter";
import WorkshopRegForm from "../components/forms/WorkshopRegForm";
import HtmlContent from "../components/atoms/HtmlContent";

const WorkShopDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/workshop", breadcrumb: "Workshop" },
    {
      path: "/workshop/" + slug,
      breadcrumb: slug ? slug?.replace(",", " ") : "No Data",
    },
  ];

  const { data } = useFetchData<Service[]>(() =>
    handlerApi(`/api/services?populate=*&filters[slug][$eq]=${slug}`)
  );

  // const termsAndCondition = data?.[0].attributes.termsAndCondition.split("\n");
  const termsAndCondition = data?.[0].attributes.termsAndCondition
    .split("\n")
    .map((item) => item.replace(/^-/, "").trim())
    .filter((item) => item !== "");

  return (
    <GlobalLayout breadCrumbs={bc}>
      {data && data?.length > 0 ? (
        <>
          <Container
            orientation="horizontal"
            firstElement={
              <figure className="w-full h-[280px] md:h-[422px]">
                {data[0].attributes.image && (
                  <Image
                    customClass="w-auto h-full rounded-lg"
                    src={
                      process.env.REACT_APP_BASE_URL! +
                      data[0].attributes.image.data.attributes.url
                    }
                    alt={data[0].attributes.serviceTitle}
                  />
                )}
              </figure>
            }
            secondElementClasses="flex flex-col justify-around gap-5 md:ml-5"
            secondElement={
              <>
                <Heading headingType="h3">
                  {data[0].attributes.serviceTitle}
                </Heading>
                <div className="flex items-center gap-5">
                  <Image
                    customClass="object-cover object-center w-6 h-6 md:w-8 md:h-8"
                    src={calendar}
                    alt={"calendar " + data[0].attributes.serviceTitle}
                  />
                  <span className="text-base text-[#939393]">
                    {formatDate(data[0].attributes.eventStart)}
                  </span>
                </div>
                <div>
                  <span className="font-semibold">Deskripsi:</span>
                  <br />
                  {<HtmlContent content={data[0].attributes.content} />}
                </div>
              </>
            }
          />
          <article className="">
            <div className="flex gap-3 mt-12 mb-6">
              <Image customClass="object-none" src={Plane} />
              <Heading headingType="h2">Persyaratan Pendaftaran</Heading>
            </div>
            <ul className="md:mb-[106px]">
              {termsAndCondition?.map((term, index) => (
                <li key={index} className="flex items-start gap-x-3">
                  <span className="font-semibold text-2xl">• </span>
                  {term}
                </li>
              ))}
            </ul>
            <div className="md:bg-[#EF841A] mt-12 md:py-[106px]">
              <WorkshopRegForm serviceId={data[0].id} serviceName={data[0].attributes.serviceTitle} />
            </div>
          </article>
        </>
      ) : (
        <div className="w-full h-full ">
          <Heading headingType="h1">
            Workshop not found with url: <code>{"workshop/" + slug}</code>
          </Heading>
        </div>
      )}
    </GlobalLayout>
  );
};

export default WorkShopDetail;
