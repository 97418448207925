import "../index.css";
import Accordion from "./Accordion";

type FaqProps = {
  faq: {
    title: string;
    content: string;
  }[];
};

const Faq = ({ faq }: FaqProps) => {
  return (
    <div
      id="faq"
      className="mb-5 font-andika justify-center items-center mt-10 md:mt-16 px-5 sm:px-12 lg:px-20 py-5 w-full max-w-[1440px]"
    >
      <h2 className="text-4xl md:text-5xl font-bold font-andika mb-12 text-center">
        Frequently Asked Question
      </h2>
      <div className="list">
        {faq.map((item, index) => (
          <Accordion
            key={index}
            datas={{
              answer: item.content,
              question: item.title,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
