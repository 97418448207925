import React from "react";
import { Link } from "react-router-dom";

type Props = {
  action?: (e: React.MouseEvent) => void;
  customClasses?: string;
  variant?: "primary" | "secondary";
  children: React.ReactNode;
  buttonType: "Link" | "button";
  link?: string;
  isExternal?: boolean;
  isDisabled?: boolean;
};

const Button = ({
  action,
  customClasses,
  variant,
  children,
  buttonType,
  link,
  isExternal,
  isDisabled,
}: Props) => {
  let buttonComponent = null;
  const primaryClasses =
    "bg-[#3BB3C3] hover:bg-white border-2 hover:border-[#3BB3C3] hover:text-[#3BB3C3] transition-all ease-in-out duration-150";
  const secondaryClasses =
    "border-[#3BB3C3] border-2 hover:bg-[#3BB3C3] hover:text-white transition-all ease-in-out duration-150";
  const baseClasses = "py-2 px-5 md:py-3 md:px-8 rounded-3xl";

  if (buttonType === "Link") {
    // Handle Link type
    buttonComponent = (
      <Link
        target={isExternal ? "_blank" : "_self"}
        to={link ? link : "#"}
        className={`btn ${baseClasses} ${
          variant === "primary" ? primaryClasses : secondaryClasses
        } ${customClasses}`}
      >
        {children}
      </Link>
    );
  } else if (buttonType === "button") {
    // Handle button type
    buttonComponent = (
      <button
        type="button"
        disabled={isDisabled}
        onClick={(e) => {
          action!(e);
        }}
        className={`btn ${baseClasses} ${
          variant === "primary" ? primaryClasses : secondaryClasses
        } ${customClasses} disabled:bg-gray-200 disabled:border-none disabled:text-gray-400`}
      >
        {children}
      </button>
    );
  }

  return buttonComponent;
};

export default Button;
