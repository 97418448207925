type Props = {
  content: string;
};

const HtmlContent = ({ content }: Props) => {
  if (content) {
    return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
  } else {
    return <div>Loading...</div>;
  }
};

export default HtmlContent;
