import Hero from "../components/beranda/Hero";
import Info from "../components/beranda/Info";
import Layanan from "../components/beranda/Layanan";
import Kegiatanaruna from "../components/rumaharuna/Kegiatanaruna";
import Faq from "../components/Faq";
import { useFetchData } from "../hooks/fetchData";
import React from "react";
import { handlerApi } from "../service/handlerApi";
import { Home } from "../interfaces/home.interface";
import HomePageLayout from "../components/layouts/HomePageLayout";

const Index: React.FC = () => {
  const { data } = useFetchData<Home>(() =>
    handlerApi(
      "/api/home-page?populate[seo][populate]=*&populate[firstSection][populate]=*&populate[secondSection][populate]=*&populate[faqs][populate]=*"
    )
  );

  const slideDatas = {
    SlideTitle: data?.attributes.seo?.pageTitle || "Rumah Aruna",
    SlideDesc: data?.attributes.seo?.pageDescription || "Rumah Aruna",
    SlideTelp: parseInt(data?.attributes.seo?.pageMeta || "0", 10) || 0,
    SlideImage: data?.attributes.seo?.pageBanner.data.attributes.url || "",
  };

  const info =
    data?.attributes.firstSection?.map((item) => ({
      id: item.id,
      sectionTitle: item.sectionTitle,
      sectionContent: item.sectionContent,
      sectionIcon: item.sectionIcon.data.attributes.url,
    })) || [];

  const layanan =
    data?.attributes.secondSection?.map((item) => ({
      id: item.id,
      sectionTitle: item.sectionTitle,
      sectionContent: item.sectionContent,
      sectionIcon: item.sectionIcon.data.attributes.url,
    })) || [];

  const faq =
    data?.attributes.faqs?.map((item) => ({
      title: item.title,
      content: item.content,
    })) || [];

  return (
    <>
      <HomePageLayout>
        <Hero slideDatas={slideDatas} />
        <Info info={info} />
        <Layanan Layanan={layanan} />
        <Kegiatanaruna customClass="px-5 sm:px-12 lg:px-20 py-5 w-full max-w-[1440px]" />
        <Faq faq={faq} />
      </HomePageLayout>
    </>
  );
};

export default Index;
