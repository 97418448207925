import Heading from "../components/atoms/Heading";
import HtmlContent from "../components/atoms/HtmlContent";
import Image from "../components/atoms/Image";
import Text from "../components/atoms/Text";
import GlobalLayout from "../components/layouts/GlobalLayout";
import { useFetchData } from "../hooks/fetchData";
import { About } from "../interfaces/about.interface";
import { handlerApi } from "../service/handlerApi";

const bc = [
  { path: "/", breadcrumb: "Beranda" },
  { path: "/about", breadcrumb: "Tentang Rumah Aruna" },
];

const AboutPage = () => {
  const { data } = useFetchData<About>(() =>
    handlerApi(
      "/api/about-us?populate[0]=sections.sectionIcon&populate[1]=seo.featuredImage"
    )
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      <Heading variant="primary" headingType="h1">
        {data ? data?.attributes.seo.pageTitle : "No title"}
      </Heading>
      <div className="flex flex-col gap-y-10 my-10">
        {data ? (
          data.attributes.sections.map((section, index) => {
            return (
              <div
                key={index}
                className={`flex flex-col md:flex-row justify-between w-full bg-red gap-5 ${
                  index % 2 !== 0 && "md:flex-row-reverse"
                }`}
              >
                <div className="w-full">
                  <Image
                    src={
                      process.env.REACT_APP_BASE_URL +
                      section.sectionIcon.data.attributes.url
                    }
                  />
                </div>
                <div className="w-full grid gap-5">
                  <Heading customClass="text-[#252525]" headingType="h2">
                    {section.sectionTitle}
                  </Heading>
                  <Text>
                    <HtmlContent content={section.sectionContent} />
                  </Text>
                </div>
              </div>
            );
          })
        ) : (
          <div>Data not Found!</div>
        )}
      </div>
    </GlobalLayout>
  );
};

export default AboutPage;
