import { useFetchData } from "../../hooks/fetchData";
import { Gallery } from "../../interfaces/gallery.interface";
import { handlerApi } from "../../service/handlerApi";
import Image from "../atoms/Image";

type KegiatanarunaProps = {
  customClass?: string;
};

const Kegiatanaruna = ({ customClass }: KegiatanarunaProps) => {
  const { data, loading } = useFetchData<Gallery[]>(() =>
    handlerApi("/api/galleries?populate=*")
  );
  if (loading) return <div>Loading...</div>;

  return (
    <div className={`${customClass} mt-20`}>
      <h2 className="text-4xl md:text-5xl font-bold font-andika mb-12 text-center">
        Kegiatan
      </h2>
      <div className="w-full mt-5 md:mt-10 grid grid-cols-2 sm:grid-cols-3 gap-4">
        {data != null &&
          data.map((value, index) => {
            return (
              <Image
                customClass="rounded-xl aspect-video w-full h-full"
                key={index}
                src={
                  process.env.REACT_APP_BASE_URL +
                  value.attributes.image.data.attributes.url
                }
                alt={value.attributes.image.data.attributes.url}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Kegiatanaruna;
