import { Link } from "react-router-dom";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import MiniCard from "./MiniCard";

type Props = {
  curriculum: {
    title: string;
    description: string;
    icon: string;
  }[];
};

const Kurikulumaruna = ({ curriculum }: Props) => {
  // Ganti nama komponen menjadi 'Kurikulumaruna' (dengan huruf kapital)
  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="my-20 font-andika">
      <p className="text-4xl font-bold text-center">Kurikulum Rumah Aruna</p>
      <div className="text-right pr-10  text-[16px] sm:text-[20px] mt-5 ">
        <Link
          to={"/curriculum"}
          className="text-[#3BB3C3] flex justify-end items-center"
          onClick={handleNavClick}
        >
          Lihat Kurikulum
          <span className="pl-2">
            <IoIosArrowDroprightCircle className="text-[#3bb3c3] text-2xl" />
            {/* <i className="fa-solid fa-chevron-up fa-rotate-90 p-1 bg-[#3BB3C3] hover:bg-slate-500 text-white rounded-full " /> */}
          </span>
        </Link>
      </div>
      <div className="max-w-7xl sm:mx-auto p-5 justify-center items-center sm:m-8 grid grid-cols-1 mt-5 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {curriculum.map((item, index) => (
          <MiniCard
            key={index}
            description={item.description}
            icon={item.icon}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Kurikulumaruna;
