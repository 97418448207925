import Layanansdc from "../components/rumahsdc/Layanansdc";
import Kegiatanaruna from "../components/rumaharuna/Kegiatanaruna";
import GlobalLayout from "../components/layouts/GlobalLayout";
import Heading from "../components/atoms/Heading";
import Container from "../components/atoms/Container";
import { useFetchData } from "../hooks/fetchData";
import { ArunaSDC } from "../interfaces/arunaSdc.interface";
import { handlerApi } from "../service/handlerApi";
import Text from "../components/atoms/Text";
import Image from "../components/atoms/Image";
import { Plane } from "../assets/img/icon";
import HtmlContent from "../components/atoms/HtmlContent";

const ArunaSDCPage = () => {
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/aruna-sdc", breadcrumb: "Profil Aruna SDC" },
  ];

  const { data } = useFetchData<ArunaSDC>(() =>
    handlerApi(
      "/api/service-page-sdc?populate[0]=seo.pageBanner&populate[1]=infoSections&populate[2]=services.icon"
    )
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      <Heading headingType="h1" customClass="text-[#EF841A]">
        {data && data?.attributes.seo.pageTitle}
      </Heading>
      <Container
        customClass="gap-10"
        orientation="horizontal"
        firstElement={<Text>{data?.attributes.seo.pageDescription}</Text>}
        secondElement={
          data?.attributes.seo.pageBanner ? (
            <Image
              src={
                process.env.REACT_APP_BASE_URL +
                data?.attributes.seo.pageBanner.data.attributes.url
              }
            />
          ) : (
            "Image Not Found"
          )
        }
      />
      {data?.attributes.infoSections
        ? data.attributes.infoSections.map((infoSection, index) => {
            return (
              <Container
                key={index}
                customClass="gap-y-5 mt-12 md:mt-10"
                firstElement={
                  <div className="flex mt-10 md:mt-12 gap-3">
                    <Image customClass="object-none" src={Plane} />
                    <Heading headingType="h2">{infoSection.title}</Heading>
                  </div>
                }
                secondElement={
                  <Text>
                    <HtmlContent content={infoSection.content} />
                  </Text>
                }
              />
            );
          })
        : "Data not found"}
      <Layanansdc serviceMenus={data?.attributes.services} />
      <Kegiatanaruna />
    </GlobalLayout>
  );
};

export default ArunaSDCPage;
