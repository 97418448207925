import React from "react";

type Props = {
  name?: string;
  cols?: number;
  rows?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  customClasses?: string;
  value?: string;
};

const TextArea = ({
  cols,
  rows,
  onChange,
  name,
  placeholder,
  customClasses,
  value,
}: Props) => {
  const finalClases = `w-full px-5 py-3 border border-[#E0E0E0] rounded-lg ${customClasses}`;

  return (
    <textarea
      className={finalClases}
      name={name}
      cols={cols ? cols : 30}
      rows={rows ? rows : 10}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    ></textarea>
  );
};

export default TextArea;
