import Image from "../atoms/Image";

type Props = {
  icon?: string;
  title?: string;
  description?: string;
  children?: string | React.ReactNode;
};

const MiniCard = ({ description, icon, title, children }: Props) => {
  return (
    <div className="bg-white h-full w-full mx-auto rounded-md p-6 shadow-lg flex flex-col items-center mb-5">
      <div>
        <Image
          src={`${process.env.REACT_APP_BASE_URL}${icon}`}
          alt={title}
          customClass="sm:h-[76px] sm:w-[76px] h-[50px] w-[50px]"
        />
      </div>
      <div className="aspect-w-1 aspect-h-1">
        <div className="flex flex-col items-center text-center">
          <h3 className="text-sm sm:text-[16px] my-6">{title}</h3>
          {description && (
            <p className="text-xs sm:text-[12px] ">{description}</p>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default MiniCard;
