import React from "react";
import { Link } from "react-router-dom";
import { handlerApi } from "../../service/handlerApi";
import { useFetchData } from "../../hooks/fetchData";
import { Article } from "../../interfaces/Article.interface";

const Artikellainnya = () => {
  const { data } = useFetchData<Article[]>(() =>
    handlerApi("/api/articles?populate=*")
  );

  return (
    <div className="md:mx-0 mt-14 md:mt-0">
      <h3 className=" sm:text-[36px] text-[28px] mt-5 sm-mt-0 font-bold">
        Artikel Lainnya
      </h3>
      {data?.map((artikel) => (
        <React.Fragment key={artikel.id}>
          <Link to={`/artikel/${artikel.attributes.slug}`}>
            <p className="sm:text-[24px] text-[18px] my-2 sm:my-5 sm:leading-[40px] hover:scale-105 transition-all duration-100 ease-in ">
              {artikel.attributes.articleTtitle}
            </p>
          </Link>
          <hr />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Artikellainnya;
