import { Link } from "react-router-dom";
import Image from "../atoms/Image";

export type SectionProps = {
  iconSrc?: string;
  for?: string;
  content: string;
  clickableURL?: string;
};

const Section = (props: SectionProps) => {
  return (
    <div className="my-3">
      {props.iconSrc && <Image src={props.iconSrc} alt={props.for} />}
      <Link to={props.clickableURL ? props.clickableURL : "#"}>
        {props.content}
      </Link>
    </div>
  );
};

export default Section;
