import { IoIosArrowDroprightCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import { Layanan } from "../../interfaces/arunaSdc.interface";
import Heading from "../atoms/Heading";
import MiniCard from "../rumaharuna/MiniCard";

type Props = {
  serviceMenus?: Layanan[];
};

const Layanansdc = ({ serviceMenus }: Props) => {
  return (
    <>
      <Heading headingType="h2" customClass="text-center my-10">
        Layanan Kami
      </Heading>
      <div className="max-w-7xl px-[30px] sm:mx-auto justify-center items-center sm:m-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
        {serviceMenus &&
          serviceMenus.length > 0 &&
          serviceMenus.map((serviceMenu, index) => {
            return (
              <MiniCard
                key={index}
                title={serviceMenu.serviceTitle}
                icon={serviceMenu.icon.data.attributes.url}
              >
                <Link to={serviceMenu.clickableURL}>
                  <IoIosArrowDroprightCircle className="text-[#3BB3C3] text-4xl" />
                </Link>
              </MiniCard>
            );
          })}
      </div>
    </>
  );
};

export default Layanansdc;
