import { useState } from "react";
import Input from "../atoms/Input";
import Heading from "../atoms/Heading";
import Button from "../atoms/Button";
import Swal from "sweetalert2";
import { HTTPAruna } from "../../service/handlerApi";

type WorkshopRegFormType = {
  fullName: string;
  email: string;
  phoneNumber: string;
  service: number;
};

type Props = {
  serviceId: number;
  serviceName?: string;
};

const WorkshopRegForm = ({ serviceId, serviceName }: Props) => {
  const [workshopRegForm, setWorkshopRegForm] = useState<WorkshopRegFormType>({
    email: "",
    fullName: "",
    service: serviceId,
    phoneNumber: "",
  });
  const [emailError, setEmailError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const isFormValid =
    !emailError &&
    !phoneError &&
    workshopRegForm.fullName &&
    workshopRegForm.service &&
    workshopRegForm.phoneNumber;

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phonePattern = /^[1-9][0-9]*$/;
    if (!phonePattern.test(phoneNumber)) {
      setPhoneError("Phone number format: 82123456789");
    } else {
      setPhoneError("");
    }
  };

  const handleEmailChange = (newValue: string) => {
    setWorkshopRegForm((prevData) => ({
      ...prevData,
      email: newValue,
    }));
    validateEmail(newValue);
  };

  const handlePhoneNumberChange = (newValue: string) => {
    setWorkshopRegForm((prevData) => ({
      ...prevData,
      phoneNumber: newValue,
    }));
    validatePhoneNumber(newValue);
  };

  const redirectToWhatsApp = () => {
    const phoneNumber = process.env.REACT_APP_WA_PHONE || "6281284848615"; // Nomor WhatsApp yang dituju
    const message = `Halo, saya ${workshopRegForm.fullName} tertarik untuk mendaftar pada program ${serviceName}.`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    window.location.href = whatsappUrl;
  };

  const submitForm = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isFormValid) {
      HTTPAruna.post("/api/registration-forms", { data: workshopRegForm })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success submit Registration Form!",
            text: "Terimakasih pendaftaran anda sudah masuk. Silakan konfirmasi kembali ke admin.",

            confirmButtonText: "Konfirmasi WhatsApp",
          }).then((result) => {
            if (result.isConfirmed) {
              redirectToWhatsApp(); // Panggil fungsi redirectToWhatsApp setelah konfirmasi
            }
          });

          setWorkshopRegForm((prevData) => ({
            ...prevData,
            email: "",
            fullName: "",
            phoneNumber: "",
          }));
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Failed submit Registration Form!",
            text: "Mohon cek kembali data yang anda masukkan ya! " + error,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed submit Registration Form!",
        text: "Mohon cek kembali data yang anda masukkan ya!",
      });
    }
  };

  return (
    <form className="pt-7 pb-16 px-5 lg:px-20 border rounded-3xl max-w-[670px] h-auto mx-auto bg-white">
      <Heading
        headingType="h2"
        variant="secondary"
        customClass="text-center mt-5"
      >
        Daftarkan Dirimu Sekarang
      </Heading>
      <section className="grid gap-10 mt-8">
        <Input
          type="text"
          placeholder="Masukkan Nama Lengkap"
          onChange={(e) => {
            setWorkshopRegForm((prevData) => ({
              ...prevData,
              fullName: e.target.value,
            }));
          }}
          value={workshopRegForm.fullName}
        />
        <Input
          type="text"
          placeholder="Masukkan Email"
          onChange={(e) => handleEmailChange(e.target.value)}
          value={workshopRegForm.email}
          error={emailError}
        />
        <Input
          type="number"
          placeholder="Masukkan No. Handphone"
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
          value={workshopRegForm.phoneNumber}
          error={phoneError}
        />
        <Button
          buttonType="button"
          action={submitForm}
          isDisabled={!isFormValid}
        >
          Daftar Sekarang
        </Button>
      </section>
    </form>
  );
};

export default WorkshopRegForm;
