type Cell = {
  leftCell: string;
  rightCell: string;
};

type Props = {
  cells?: Cell[];
  leftHeader?: string;
  rightHeader?: string;
};

const TableWithHeader = ({ cells, leftHeader, rightHeader }: Props) => {
  const baseCellClasses = "p-5 w-1/2 border-b-8 border-white"
  const baseHeadClasses = "p-5 text-xl text-white"

  return (
    <table className="bg-red-100">
      <thead>
        <tr>
          <th className={`${baseHeadClasses} bg-[#3BB3C3]`}>{leftHeader}</th>
          <th className={`${baseHeadClasses} bg-[#EF841A]`}>{rightHeader}</th>
        </tr>
      </thead>
      <tbody>
        {cells ? cells.map((data, index) => {
          return (
            <tr key={index}>
              <td className={`${baseCellClasses} bg-[#E7FCFF]`}>{data.leftCell}</td>
              <td className={`${baseCellClasses} bg-[#FFF5EC]`}>{data.rightCell}</td>
            </tr>
          );
        }) : <div>Please insert Cells data</div>}
      </tbody>
    </table>
  );
};

export default TableWithHeader;
