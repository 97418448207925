import Heading from "../components/atoms/Heading";
import Image from "../components/atoms/Image";
import GlobalLayout from "../components/layouts/GlobalLayout";
import { useFetchData } from "../hooks/fetchData";
import { Service } from "../interfaces/Service.interface";
import { handlerApi } from "../service/handlerApi";
import Button from "../components/atoms/Button";
import { formatDate } from "../utils/dateFormatter";
import { calendar } from "../assets/img/icon";

const WorkShopPage = () => {
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/workshop", breadcrumb: "Workshop" },
  ];

  const { data } = useFetchData<Service[]>(() =>
    handlerApi("/api/services?populate=*")
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      <Heading headingType="h1" customClass="text-center my-16">
        Workshop Mendatang
      </Heading>
      <article className="grid grid-cols-1 md:grid-cols-2 gap-8 gap-y-12">
        {data ? (
          data?.map((service, index) => {
            return (
              <div
                className="py-5 px-3 grid lg:grid-cols-2 shadow-md rounded-xl"
                key={index}
              >
                <figure className="w-full h-[216px] ">
                  {service.attributes.image && (
                    <Image
                      customClass="rounded-xl w-full lg:w-auto h-full"
                      src={
                        process.env.REACT_APP_BASE_URL +
                        service.attributes?.image.data?.attributes?.url
                      }
                      alt={service.attributes.serviceTitle}
                    />
                  )}
                </figure>
                <div className="flex flex-col mt-5 lg:mt-0 lg:ml-5 xl:8 justify-between gap-5">
                  <h1 className="text-2xl">
                    {service.attributes.serviceTitle}
                  </h1>
                  <div className="flex items-center gap-x-5">
                    <Image
                      customClass="object-cover object-center w-6 h-6"
                      src={calendar}
                      alt={"calendar " + service.attributes.serviceTitle}
                    />
                    <span className="text-[#939393] text-sm">
                      {formatDate(service.attributes.eventStart)}
                    </span>
                  </div>
                  <Button
                    buttonType="Link"
                    link={`/workshop/${service.attributes.slug}`}
                    variant="primary"
                    customClasses="text-white hover:text-[#2e2e2e] border-2 text-center"
                  >
                    Daftar Sekarang!
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div>There is no upcoming Workshop</div>
        )}
      </article>
    </GlobalLayout>
  );
};

export default WorkShopPage;
