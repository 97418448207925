import { Link } from "react-router-dom";
import Image from "./Image";

type Props = {
  purpose: string;
  content: string;
  clickableURL: string;
  iconURL: string;
  customClasses?: string;
};

const LinkWithIcon = ({
  clickableURL,
  content,
  iconURL,
  purpose,
  customClasses,
}: Props) => {
  const finalClasses = `flex gap-4 items-center hover:text-[#EF841A] ${customClasses}`;
  
  return (
    <Link to={clickableURL} className={finalClasses}>
      {iconURL && (
        <Image
          src={process.env.REACT_APP_BASE_URL + iconURL}
          alt={purpose + " Rumah Aruna"}
          isLazy
        />
      )}
      <span className="text-xl">{content}</span>
    </Link>
  );
};

export default LinkWithIcon;
