import { Plane } from '../assets/img/icon';
import TableWithHeader from '../components/Curriculum/TableWithHeader';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import HtmlContent from '../components/atoms/HtmlContent';
import Image from '../components/atoms/Image';
import GlobalLayout from '../components/layouts/GlobalLayout';
import { useFetchData } from '../hooks/fetchData';
import { curriculumPage } from '../interfaces/curriculumPage.interface';
import { handlerApi } from '../service/handlerApi';

const CurriculumPage = () => {
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/curriculum", breadcrumb: "Kurikulum" },
  ];

  const { data } = useFetchData<curriculumPage>(() =>
    handlerApi(
      "/api/curriculum-page?populate[0]=tables.tables&populate[1]=seo.pageBanner&populate=*&populate[2]=curriculums"
    )
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      <Heading headingType='h1' customClass='mb-10' variant='primary'>{data ? data?.attributes.seo.pageTitle : "Loading..."}</Heading>
      {data?.attributes.curriculums
        ? data.attributes.curriculums.data.map((curriculum, index) => {
            return (
              <>
                <Container
                  key={index}
                  orientation='vertical'
                  customClass="gap-5 my-10"
                  firstElement={
                    <div className="flex gap-3">
                      <Image customClass="object-none" src={Plane} />
                      <Heading headingType="h2" variant='secondary'>{curriculum.attributes.title}</Heading>
                    </div>
                  }
                  secondElement={<HtmlContent content={curriculum.attributes.content} />}
                />
                { index === 1 && <TableWithHeader leftHeader={data.attributes.tables[0].tableLeftHeader} rightHeader={data.attributes.tables[0].tableRightHeader} cells={data.attributes.tables[0].tables} />}
                { index === 2 && <TableWithHeader leftHeader={data.attributes.tables[1].tableLeftHeader} rightHeader={data.attributes.tables[1].tableRightHeader} cells={data.attributes.tables[1].tables} />}
              </>
            );
          })
        : "Data not found"}
    </GlobalLayout>
  );
};

export default CurriculumPage;
