type Props = {
  src: string;
  alt?: string;
  isLazy?: boolean;
  customClass?: string;
};

const Image = ({ src, alt, isLazy, customClass }: Props) => {
  return (
    <img
      className={`aspect-auto object-cover ${customClass}`}
      src={src}
      alt={alt ? alt : src}
      loading={isLazy ? "lazy" : "eager"}
    />
  );
};

export default Image;
