import React from "react";

type Props = {
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "large" | "small";
  headingType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  customClass?: string | undefined;
};

const Heading = ({ children, variant, headingType, customClass }: Props) => {
  // Define the default classes for headings
  const baseClasses = "font-semibold text-[40px]";

  // Define variant-specific classes
  let variantClasses = "";

  switch (variant) {
    case "primary":
      variantClasses = "text-[40px] sm:text-[36px] text-[#EF841A]"; // Define your Tailwind CSS classes for the 'primary' variant
      break;
    case "secondary":
      variantClasses = "text-xl text-[#000]"; // Define your Tailwind CSS classes for the 'secondary' variant
      break;
    case "large":
      variantClasses = "text-lg"; // Define your Tailwind CSS classes for the 'large' variant
      break;
    case "small":
      variantClasses = "text-sm"; // Define your Tailwind CSS classes for the 'small' variant
      break;
    default:
      variantClasses = ""; // No variant specified
  }

  // Combine base and variant classes
  const combinedClasses = `${baseClasses} ${variantClasses} ${customClass}`;

  // Render the heading element with the specified type and combined classes
  const HeadingTag = headingType;
  return <HeadingTag className={combinedClasses}>{children}</HeadingTag>;
};

export default Heading;
