import { Program } from "../../interfaces/rumahAruna.interface";
import Button from "../atoms/Button";
// import { AiOutlineDownload } from "react-icons/ai";
import Image from "../atoms/Image";

type Props = {
  programs: Program[];
};

const ProgramCard = ({ programs }: Props) => {
  return (
    <>
      <div className="text-center">
        <h3 className="text-4xl md:text-5xl font-bold">Program Kami</h3>
      </div>
      {programs.map((program, index) => {
        return (
          <div
            key={index}
            className=" md:flex items-center gap-x-10 mt-5 md:mt-10 mb-10 md:mb-16 w-full rounded-[20px] p-[33px] shadow-md"
          >
            <section className="md:w-1/2">
              <Image
                customClass="w-full md:w-[323px] h-[194px] rounded-xl object-cover"
                src={
                  process.env.REACT_APP_BASE_URL +
                  program.attributes.featuredImage.data.attributes.url
                }
              />
            </section>
            <section className="w-full flex flex-col gap-y-7">
              <p className="mt-8 md:mt-10 font-semibold text-2xl md:text-4xl">
                {program.attributes.programTitle}
              </p>
              <div className="flex gap-x-5 md:gap-x-10">
                <Button
                  buttonType="Link"
                  variant="primary"
                  customClasses="text-white text-sm md:text-base"
                  link={program.attributes.clickableURLAction}
                >
                  <span>Konsultasi Sekarang</span>
                </Button>
                <Button
                  isExternal
                  buttonType="Link"
                  variant="secondary"
                  customClasses="text-[#3BB3C3] text-sm md:text-base flex gap-x-2 items-center"
                  link={
                    process.env.REACT_APP_BASE_URL! +
                    program.attributes.downloadableMedias.data[0].attributes.url
                  }
                >
                  {/* <AiOutlineDownload className="text-xl" /> */}
                  <span className="">Panduan Pendaftaran</span>
                </Button>
              </div>
            </section>
          </div>
        );
      })}
    </>
  );
};

export default ProgramCard;
