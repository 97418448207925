import Artikellainnya from "./Artikellainnya";
import { Article } from "../../interfaces/Article.interface";
import { handlerApi } from "../../service/handlerApi";
import { useParams } from "react-router-dom";
import GlobalLayout from "../layouts/GlobalLayout";
import { useEffect, useState } from "react";
import Image from "../atoms/Image";

const Detailartikel = () => {
  const { slug } = useParams<{ slug: string }>();
  const [data, setData] = useState<Article[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handlerApi(
          `/api/articles?populate=*&filters[slug][$eq]=${slug}`
        );
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [slug]);

  const article = data?.[0];

  if (!article)
    return (
      <div className="h-screen justify-center flex items-center">
        Article Not Found
      </div>
    );

  const { attributes } = article; // Destructuring object article

  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/artikel", breadcrumb: "Artikel" },
    {
      path: `/artikel/${attributes?.slug}`,
      breadcrumb: attributes?.articleTtitle,
    },
  ];

  return (
    <GlobalLayout breadCrumbs={bc}>
      <div className="w-full bg-white font-andika">
        <div className="lg:flex justify-center gap-10">
          <div className="">
            <div className="mt-10 sm:flex">
              <Image
                src={`${process.env.REACT_APP_BASE_URL}${
                  attributes?.featuredImage?.data.attributes.url || ""
                }`}
                alt="detail article"
                customClass="w-full h-[300px] md:min-w-[700px] object-cover object-center sm:h-[400px] rounded-xl"
              />
            </div>
            <div className="mt-10 md:w-[700px] justify-text-center ">
              <h3 className="sm:text-[36px] text-[25px] font-bold font-w-400  sm:leading-[36px]">
                {attributes?.articleTtitle || ""}
              </h3>
              <p className="text-[#4F4F4F] my-3 text-[18px] sm:text-[24px] font-w-400  leading-[36px]">
                {attributes?.excerpt || ""}
              </p>
              <p
                className="text-[#4F4F4F]  text-[20px] text-justify sm:text-[24px]  mb-4 font-w-400  sm:leading-[36px]"
                dangerouslySetInnerHTML={{ __html: article.attributes.content }}
              ></p>
            </div>
          </div>
          <div>
            <Artikellainnya />
          </div>
        </div>
      </div>
    </GlobalLayout>
  );
};

export default Detailartikel;
