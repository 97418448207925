type Props = {
  children: React.ReactNode;
};

const HomePageLayout = ({ children }: Props) => {
  return (
    <>
      <main className="w-full flex flex-col justify-center items-center font-andika bg-white py-32 lg:py-50">
        {children}
      </main>
    </>
  );
};

export default HomePageLayout;
