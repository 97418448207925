import { plane2 } from "../../assets/img/galery/index";
import Image from "../atoms/Image";
import { MdWhatsapp } from "react-icons/md";

const openWhatsAppChat = (phoneNumber: number) => {
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  window.open(url, "_blank");
};

type HeroProps = {
  slideDatas: {
    SlideTitle: string;
    SlideDesc: string;
    SlideTelp: number;
    SlideImage: string;
  };
};

const Hero = ({ slideDatas }: HeroProps) => {
  return (
    <div className="px-5 sm:px-12 lg:px-20 py-5 w-full max-w-[1440px]">
      <div className="flex flex-col-reverse md:flex-row">
        <div className="md:w-2/3 flex flex-col justify-center md:justify-start items-center md:items-start">
          <h3 className="sm:text-[64px] text-[40px] font-satisfy text-[#EF841A]">
            {slideDatas.SlideTitle}
          </h3>
          <p className="mt-2 sm:mt-5 text-center md:text-start font-andika text-[18px] lg:text-[28px] sm:h-[166px] text-[#EF841A] md:pr-10">
            {slideDatas.SlideDesc}
          </p>
          <button
            className="sm:pl-0 z-10 cursor-pointer rounded-full mt-10 md:mt-0 lg:mt-16"
            onClick={() => openWhatsAppChat(slideDatas.SlideTelp)}
          >
            <div className="cursor-pointer hover:scale-105 transition-all ease-in-out duration-150 flex justify-center items-center text-white bg-[#3BB3C3] w-48 sm:w-80 py-2 sm:py-3 rounded-full">
              <MdWhatsapp className="text-2xl sm:text-4xl flex justify-center items-center pr-2" />
              <div className="font-andika text-sm sm:text-[24px]">
                Konsultasi Sekarang
              </div>
            </div>
          </button>
        </div>

        <div className="md:w-1/2 lg:w-2/5 mx-auto">
          <Image
            customClass="w-full h-auto md:mt-10 xl:mt-0"
            src={process.env.REACT_APP_BASE_URL + slideDatas.SlideImage}
            alt={`hero`}
          />
        </div>
        <div className="mt-[230px] ml-[380px] absolute hidden lg:block">
          <Image
            src={plane2}
            alt="plane2"
            customClass="w-[231.552px] h-[162.5px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
