import React, { useState } from "react";
import { logorumah } from "../../assets/img/icon/index";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button";
import { MdMenu, MdClose } from "react-icons/md";
import { BiSolidDownArrow } from "react-icons/bi";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const loacation = useLocation();

  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="shadow-md w-full text-[16px] text-[#3BB3C3] top-0 left-0 fixed bg-white z-50">
      <div className="md:flex max-w-[1440px] mx-auto items-center justify-between  py-4 px-5 sm:px-12 lg:px-20 ">
        <div className="flex items-center">
          <div>
            <Link to={"/"}>
              <img
                src={logorumah}
                alt="logoaruna"
                className="w-[123.77px]  h-[70px] object-contain"
              />
            </Link>
          </div>
          <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-8 cursor-pointer md:hidden"
          >
            <div>
              {open ? (
                <div className="text-4xl">
                  <MdClose />
                </div>
              ) : (
                <div className="text-4xl">
                  <MdMenu />
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <ul
            className={`md:flex md:items-center md:pb-0 pb-3 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-20 " : "top-[-490px]"
            }`}
          >
            <li
              className={`sm:ml-8 rounded-lg p-2 ${
                loacation.pathname === "/" ? "text-[#EF841A]" : ""
              } text-[16px] md:my-0 my-7`}
              onClick={handleNavClick}
            >
              <Link to={`/`}>Beranda</Link>
              {loacation.pathname === "/" && (
                <div className="bg-[#EF841A] pt-[3px] mr-8 md:mr-0 rounded-full"></div>
              )}
            </li>
            <li
              className={`sm:ml-8 rounded-lg p-2 ${
                loacation.pathname === "/tentang" ? "text-[#EF841A]" : ""
              } text-[16px] md:my-0 my-7`}
              onClick={handleNavClick}
            >
              <Link to={`tentang`}>Tentang Kami</Link>
              {loacation.pathname === "/tentang" && (
                <div className="bg-[#EF841A] pt-[3px] mr-8 md:mr-0 rounded-full"></div>
              )}
            </li>
            <li
              className={`md:ml-8 text-[16px] md:my-0 my-7 rounded-lg p-2  hover:radius-xl ${
                loacation.pathname === "/rumah-aruna" ? "text-[#EF841A]" : ""
              } ${
                loacation.pathname === "/aruna-sdc" ? "text-[#EF841A]" : ""
              } ${
                loacation.pathname === "/curriculum" ? "text-[#EF841A]" : ""
              }`}
            >
              <div className="flex items-center">
                <Button />
                <div className="text-xs pl-1">
                  <BiSolidDownArrow />
                </div>
              </div>
              {loacation.pathname === "/rumah-aruna" && (
                <div className="bg-[#EF841A] pt-[3px] mr-8 md:mr-0 rounded-full"></div>
              )}
              {loacation.pathname === "/aruna-sdc" && (
                <div className="bg-[#EF841A] pt-[3px] mr-8 md:mr-0 rounded-full"></div>
              )}
            </li>
            <li
              className={`md:ml-8 text-[16px] md:my-0 my-7 rounded-lg p-2 ${
                loacation.pathname === "/contact" ? "text-[#EF841A]" : ""
              }`}
              onClick={handleNavClick}
            >
              <Link to={`contact`}>Kontak Kami</Link>
              {loacation.pathname === "/contact" && (
                <div className="bg-[#EF841A] pt-[3px] mr-8 md:mr-0 rounded-full"></div>
              )}
            </li>
            <li
              className={`md:ml-8 text-[16px] md:my-0 my-7 rounded-lg p-2 ${
                loacation.pathname === "/artikel" ? "text-[#EF841A]" : ""
              }`}
              onClick={handleNavClick}
            >
              <Link to={`artikel`}>Artikel</Link>
              {loacation.pathname === "/artikel" && (
                <div className="bg-[#EF841A] pt-[3px] mr-8 md:mr-0 rounded-full"></div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
